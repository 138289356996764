import React from "react";
import Tale from "./Tale";
import About from "./About";
import { MDBContainer, MDBRow } from "mdbreact";
import InfoBar from "../infobar/InfoBar";

const AboutWrapper = (props) => {
  return (
    <MDBContainer fluid>
      {props.aboutLoaded && (
        <InfoBar
          text="WHO WE ARE"
          color="white"
          marginBottom="9vh"
          marginLeft="-5vh"
          className={"info-bar-position"}
        />
      )}

      <MDBRow>
        {props.taleLoaded && <Tale />}
        {props.aboutLoaded && (
          <About
            borderFadeIn={props.borderFadeIn}
            firstAboutLoad={props.firstAboutLoad}
            aboutLoaded={props.aboutLoaded}
          />
        )}
      </MDBRow>
    </MDBContainer>
  );
};

export default AboutWrapper;
