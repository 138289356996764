import React from "react";
import SampleVideo from "../../assets/video/noble.mp4";

const TextAnimation = (props) => {
  return (
    <div className={"banner-container"}>
      <div className="banner">
        <video autoPlay data-keepplaying muted loop style={{backgroundColor: "#0b0b0b"}}>
          <source src={SampleVideo} type="video/mp4" />
        </video>
        <div className="banner-text">
          <h2 className="trans-animation trans-up trans-animation-desktop">
            <span>t</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-m"}>m</span>
            <span>p</span>
            <span className={"banner-span-o"}>o</span>
            <span>l</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-v"}>v</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span>t</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-m"}>m</span>
            <span>p</span>
            <span className={"banner-span-o"}>o</span>
            <span>l</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-v"}>v</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span>t</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-m"}>m</span>
            <span>p</span>
            <span className={"banner-span-o"}>o</span>
            <span>l</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-v"}>v</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span>t</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-m"}>m</span>
            <span>p</span>
            <span className={"banner-span-o"}>o</span>
            <span>l</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-v"}>v</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span>t</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-m"}>m</span>
            <span>p</span>
            <span className={"banner-span-o"}>o</span>
            <span>l</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-v"}>v</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span>t</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-m"}>m</span>
            <span>p</span>
            <span className={"banner-span-o"}>o</span>
            <span>l</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-v"}>v</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span>t</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-m"}>m</span>
            <span>p</span>
            <span className={"banner-span-o"}>o</span>
            <span>l</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-v"}>v</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
          </h2>
          <h2 className="trans-animation trans-up trans-animation-mobile">
            developmentdevelopmentdevelopmentdevelopmentdevelopmentdevelopmentdevelopmentdevelopmentdevelopment
          </h2>
          <h2 className="trans-animation trans-down trans-animation-desktop">
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
            <span className={"banner-span-n"}>n</span>
            <span className={"banner-span-o"}>o</span>
            <span className={"banner-span-margin"}>i</span>
            <span className={"banner-span-h"}>h</span>
            <span>s</span>
            <span className={"banner-span-a"}>a</span>
            <span>f</span>
          </h2>
          <h2 className="trans-animation trans-down trans-animation-mobile">
            fashionfashionfashionfashionfashionfashionfashionfashionfashionfashionfashionfashionfashion
          </h2>
          <h2 className="trans-animation trans-up trans-animation-desktop">
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
            <span className={"banner-span-n"}>n</span>
            <span>g</span>
            <span className={"banner-span-margin"}>i</span>
            <span>s</span>
            <span className={"banner-span-e"}>e</span>
            <span className={"banner-span-d"}>d</span>
          </h2>
          <h2 className="trans-animation trans-up trans-animation-mobile">
            designdesigndesigndesigndesigndesigndesigndesigndesigndesigndesigndesigndesigndesigndesign
          </h2>
        </div>
      </div>
    </div>
  );
};

export default TextAnimation;
