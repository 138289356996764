import React from "react";
import "../styles/index.scss";
import {MDBContainer} from "mdbreact";
const TemplateWrapper = ({children}) => {

  return (
    <MDBContainer fluid className={"main-container"}>
      {children}
    </MDBContainer>
  );
};

export default TemplateWrapper;
