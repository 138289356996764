import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const getAboutData = graphql`
  {
    markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
      frontmatter {
        taleDescription
        taleTitle
        taleTitleSubheading
      }
    }
  }
`;
const Tale = () => {
  const data = useStaticQuery(getAboutData);

  const {
    markdownRemark: {
      frontmatter: { taleDescription, taleTitle, taleTitleSubheading },
    },
  } = data;
  return (
    <div className={"tale-container"}>
      <div className={"tale-text-container"}>
        <div className={"tale-text-title"}>
          <span>tale</span>
          <span className={"tale-text-styling"}>:</span>
        </div>
        <div className={"tale-text-sub-heading"}>
          [<span style={{ fontStyle: "italic" }}>teil</span>] <span>noun</span>
        </div>
        <br />
        <span className={"tale-text-description"}>{taleDescription}</span>
      </div>
    </div>
  );
};

export default Tale;
