import React from "react";
import SampleVideo from "../../assets/video/noble.mp4";

const ImageAnimation = ({ firstAboutLoad, image, borderFadeIn }) => {
  return (
    <React.Fragment>
      <div
        className={
          firstAboutLoad
            ? "late-page-animation page-animation decrease-index"
            : "early-page-animation page-animation decrease-index"
        }
      >
        <div className={"animation-banner-container"}>
          <div className={"animation-banner"} >
            <div className="vimeo-player">
              <video
                autoPlay
                data-keepplaying
                muted
                loop
                style={{ height: "100vh" }}
              >
                <source src={SampleVideo} type="video/mp4" />
              </video>
            </div>
            <div className="animation-banner-image">
              <img className="animation-image-styling" src={image} alt={"Martinitoren"} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImageAnimation;
