import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import TextAnimation from "./TextAnimation";
import InfoBar from "../infobar/InfoBar";
import {
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBContainer,
} from "mdbreact";

const getHeroData = graphql`
  {
    markdownRemark(frontmatter: { templateKey: { eq: "hero" } }) {
      frontmatter {
        heroDescription
        heroSubheading
        heroTitle
      }
    }
  }
`;

const LandingPage = (props) => {
  const data = useStaticQuery(getHeroData);
  const {
    markdownRemark: {
      frontmatter: { heroTitle, heroDescription, heroSubheading },
    },
  } = data;
  return (
    <MDBContainer fluid className={"full-parent-size"}>
      {props.animation && (
        <InfoBar
          text="SCROLL DOWN"
          color="white"
          marginBottom="11vh"
          marginLeft="-4rem"
          className={".info-bar-position"}
        />
      )}

      <MDBRow
        md="12"
        style={{ color: "white" }}
        className={"view-landing-page"}
      >
        <MDBCol md="6" lg="5" xl="4">
          <div className="hero-container-four">
            <MDBCardBody cascade className="hero-text">
              <MDBCardTitle tag="h2" className="hero-header-text">
                {heroTitle}
              </MDBCardTitle>
              <div className={"case-page-two-subhead-wrapper"}>
                <MDBCardTitle tag="h1" className={"case-page-two-description"}>
                  {heroDescription}
                </MDBCardTitle>
                <span className="case-page-two-subheading">
                  {heroSubheading}
                </span>
                <div className={"case-page-two-link"}>
                  <span className={"case-page-two-link-text"}>
                    <a
                      href="#Work"
                      style={{ color: "white" }}
                      onClick={props.onClickHandler}
                    >
                      See our work
                    </a>
                  </span>
                </div>
              </div>
            </MDBCardBody>
          </div>
        </MDBCol>
        {props.animation && (
          <MDBCol md="5" lg="6" xl="7" className="hero-animation">
            {[-1, 0, 1].includes(props.index) && (
              <TextAnimation
                borderFadeIn={props.borderFadingIn}
                direction={props.direction}
                index={props.index}
              />
            )}
          </MDBCol>
        )}
      </MDBRow>
    </MDBContainer>
  );
};

LandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      description: PropTypes.string,
      subheading: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
};

export default LandingPage;
