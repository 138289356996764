import React from "react";
import PropTypes from "prop-types";
import {MDBCol, MDBRow} from "mdbreact";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import Layout from "../components/Layout";
import "typeface-montserrat";
import FullPage from "../components/FullPage";
import SEO from "../components/Seo";

export const IndexPageTemplate = ({heading, subheading, intro, main}) => (
  <MDBRow>
    <MDBCol md="12">
      <h1> {heading} </h1>
      <h2> {subheading} </h2>
    </MDBCol>

    <MDBCol md="12">
      <h3> {intro.heading} </h3>
      <p> {intro.description} </p>
    </MDBCol>

    <MDBCol md="12">
      <h3> {main.heading} </h3>
      <p> {main.description} </p>
    </MDBCol>
  </MDBRow>
);

IndexPageTemplate.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.array,
  main: PropTypes.array,
};

const Index = ({data}) => {
  return (
    <Layout>
      <SEO />
      <FullPage/>
    </Layout>
  );
};
export default Index;
