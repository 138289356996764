import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import SampleImage from "../../../static/img/martiniabout33.png";
import ImageAnimation from "./ImageAnimation";

const getAboutData = graphql`
  {
    markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
      frontmatter {
        description2
        taleDescription
        taleTitle
        taleTitleSubheading
        image2 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
const About = (props) => {
  const data = useStaticQuery(getAboutData);
  const {
    markdownRemark: {
      frontmatter: {description},
    },
  } = data;

  return (
    <div className={"about-container"}>
      <div
        className={
          props.firstAboutLoad
            ? "late-about-text-container about-text-container"
            : "early-about-text-container about-text-container"
        }
      >
        <div className={"about-text-title"}>
          <span>We are</span>
        </div>
        <div className={"about-text-description"}>
          <span>
            A personalised development company based in
            <span className={"about-transition-1"}> Groningen</span> that
            focuses on <span className={"about-transition-2"}>E-commerce.</span>{" "}
            We strive to make our client's grow their online
            <span className={"about-transition-3"}> businesses</span> with
            design and web{" "}
            <span className={"about-transition-4"}>architecture</span>
          </span>
        </div>
      </div>
      <ImageAnimation
        borderFadeIn={props.borderFadeIn}
        firstAboutLoad={props.firstAboutLoad}
        image={SampleImage}
      />
    </div>
  );
};

export default About;
