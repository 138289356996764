import React from "react";
import {Form, Row, Col} from "react-bootstrap";
import {MDBContainer, MDBCardTitle, MDBCardText} from "mdbreact";
import InfoBar from "../infobar/InfoBar";
import SampleImage from "../../../static/img/library5.png";
import SampleVideo from "../../assets/video/noble.mp4";

function FormGroupInput(props) {
  return (
    <Form.Group controlId={props.id}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        className={props.classname}
        type={props.type}
        placeholder={props.placeholder}
      />
    </Form.Group>
  );
}

const ContactForm = (props) => {
  return (
    <MDBContainer fluid className="contact-page-container">
      {props.animation && (
        <InfoBar
          text="CONTACT US"
          color="white"
          marginLeft="-2.5rem"
          marginBottom="7rem"
        /> &&
      <Row
        className={!props.borderFadeIn ? "contact-page-row form-toggle-visible" : "contact-page-row form-toggle-invisible"}>
        <Col md="6" className={"contact-page-info"} style={props.borderFadeIn ? {height: "100%"} : {}}>
          <MDBContainer fluid className="form-container-contact-page">
            <MDBCardTitle tag="h2" className="contact-page-title">
              Say hi.
            </MDBCardTitle>
            <MDBCardText tag="h5" className="contact-page-text">
              <span className={"gray-text"}>Fill in the form below or </span><span className={"orange-c"}>see other contact options</span>
            </MDBCardText>

            <Form className="form-contact-page">
              <FormGroupInput
                id={"ControlName"}
                label={"Name"}
                classname={"contact-page-form-control"}
                type={"text"}
                placeholder={"Enter your name"}
              />
              <FormGroupInput
                id={"ControlPhone"}
                label={"Phone Number"}
                classname={"contact-page-form-control"}
                type={"text"}
                placeholder={"Enter your phone"}
              />
              <FormGroupInput
                id={"formBasicEmail"}
                label={"Email address"}
                classname={"contact-page-form-control"}
                type={"email"}
                placeholder={"Enter your email"}
              />
              <Form.Group controlId="ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  className="contact-page-form-textarea"
                  as="textarea"
                  rows={5}
                />
              </Form.Group>

              <span className={"send-btn-text"}>
                  <a href={"#hero"}>Send</a>
                </span>
            </Form>
          </MDBContainer>
        </Col>
        <Col md="6">
            <div>
              <div className={"animation-banner-container"}>
                <div
                  className={"animation-banner"}
                >
                  <div className="vimeo-player-contatc-page">
                    <video
                      className="contact-page-video"
                      autoPlay
                      data-keepplaying
                      muted
                      loop
                    >
                      <source src={SampleVideo} type="video/mp4"/>
                    </video>
                  </div>
                  <div className="animation-banner-image-contact">
                    <img
                      className="animation-image-styling-contact"
                      src={SampleImage}
                      alt={"Forum Groningen"}
                    />
                  </div>
                </div>
              </div>
            </div>
        </Col>
      </Row>
        )}
    </MDBContainer>
  );
};

export default ContactForm;
