import React, {useState} from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Navbar from "./navbar/Navbar";
import FirstPage from "./landingPage/LandingPage";
import SecondPage from "./about/AboutWrapper";
import ThirdPage from "./cases/Cases";
import FifthPage from "./contact/ContactForm";
import SampleVideo from "../assets/video/noble.mp4";

const SEL = "custom-section";
const SECTION_SEL = `.${SEL}`;

// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
// This is no longer required for the scrollOverflow option.
const pluginWrapper = () => {
  require("../fullpageJSExtensions/fullpage.dragAndMove.min"); // Optional. Required when using the "fadingEffect" extension.
  require("../fullpageJSExtensions/fullpage.scrollHorizontally.min"); // Optional. Required when using the "fadingEffect" extension.
};

const originalColors = ["#0B0B0B", "#FFFCF8", "#0B0B0B", "#FFFCF8", "#0B0B0B"];

var scrollingtimout = null;
var pageDelayID;
var pageDelay = 900;

export default class FullPage extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      pageIndex: 0,
      taleLoaded: false,
      aboutLoaded: false,
      firstAboutLoad: true,
      tab: false,
      heroAnimation: true,
      projectAnimation: false,
      contactAnimation: false,
      borderFadingIn: false,
      direction: "down",
      reachOut: "",
      reRender: false,
      dark: true,
      navbarToggled: false,
      sectionsColor: [...originalColors],
      fullpages: [
        {
          page: "firstPage",
        },
        {
          page: "secondPage",
        },
        {
          page: "thirdPage",
        },
        {
          page: "fourthPage",
        },
        {
          page: "fifthPage",
        },
      ],
    };
    this.nextPage = this.nextPage.bind(this);
    this.setStatePages = this.setStatePages.bind(this);
  }


  onSlideLeave(section, origin, destination, direction) {
    if (destination.index === 1 && this.state.tab) {
      if (direction === "right") {
        destination.item.childNodes[0].childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[0].className =
          "tabIcon animated fadeInLeft slower gatsby-image-wrapper";
      } else if (direction === "left") {
        destination.item.childNodes[0].childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[0].className =
          "tabIcon animated fadeInRight slower gatsby-image-wrapper";
      }
    }

    if (destination.index === 2 && this.state.tab) {
      if (direction === "right") {
        destination.item.childNodes[0].childNodes[0].childNodes[1].childNodes[0].childNodes[0].className =
          "card-body animated fadeInLeft slow tabCradBody";
      } else if (direction === "left") {
        destination.item.childNodes[0].childNodes[0].childNodes[1].childNodes[0].childNodes[0].className =
          "card-body animated fadeInRight slow tabCradBody";
      }
    }
  }

  afterSlideLoad(section3, origin, destination, direction) {
    this.setState({tab: true});
  }

  setStatePages(origin, destination, delay) {
    clearTimeout(pageDelayID);
    pageDelayID = setTimeout(() => {
      if (destination.index === 0 && this.state.heroAnimation === false) {
        this.setState({heroAnimation: true});
      }
      if (destination.index !== 0 && this.state.heroAnimation) {
        this.setState({heroAnimation: false});
      }

      if (destination.index === 1 && this.state.taleLoaded === false) {
        this.setState({taleLoaded: true});
      }
      if (destination.index === 1 && this.state.aboutLoaded === false) {
        this.setState({aboutLoaded: true});
      }
      if (destination.index !== 1 && this.state.aboutLoaded) {
        this.setState({aboutLoaded: false});
      }
      if (origin.index === 1 && this.state.firstAboutLoad) {
        this.setState({firstAboutLoad: false});
      }

      if (destination.index === 2 && this.state.projectAnimation === false) {
        this.setState({projectAnimation: true});
      }
      if (destination.index !== 2 && this.state.projectAnimation) {
        this.setState({projectAnimation: false});
      }

      if (destination.index === 3 && this.state.tab === false) {
        this.setState({tab: true});
      }
      if (destination.index !== 3 && this.state.tab) {
        this.setState({tab: false});
      }
      if (destination.index === 4 && this.state.contactAnimation === false) {
        this.setState({contactAnimation: true, reachOut: "none"});
      }
      if (destination.index !== 4 && this.state.contactAnimation) {
        this.setState({contactAnimation: false, reachOut: ""});
      }
    }, delay);
  }

  nextPage(destination, direction) {
    this.setState({dark: false});
    this.setState({reRender: true});
    this.myRef.current.fullpageApi.moveTo(destination.anchor);
  }

  onLeave(origin, destination, direction) {
    if (scrollingtimout !== null) return false;
    this.setState({direction: direction})

    if (this.state.reRender) {
      setTimeout(() => {
        this.setState({reRender: false});
        this.setState({pageIndex: destination.index})
        this.setState({borderFadingIn: false});
      }, 500);
      return true;
    }

    if ([-1, 0, 1].includes(origin.index - destination.index)) {
      this.setState({borderFadingIn: true});
      this.setState({navbarToggled: false})
      pageDelay = 900;
    } else {
      pageDelay = 100;
    }

    scrollingtimout = setTimeout(() => {
      scrollingtimout = null;
      this.nextPage(destination, direction);
    }, pageDelay);

    return this.state.reRender;
  }

  afterLoad(origin, destination, direction) {
    this.setStatePages(origin, destination, 100, true);
    this.setState({dark: true});
    this.setState({borderFadeIn: false});
  }

  render() {
    const handleClick = () => {
      this.setState({navbarToggled: !this.state.navbarToggled})
    };

    const {fullpages} = this.state;

    if (!fullpages.length) {
      return null;
    }

    return (
      <div className="App">
        {this.state.dark && (
          <Navbar dark={this.state.dark} reachOut={this.state.reachOut} handleClick={handleClick} navbarToggled={this.state.navbarToggled}/>
        )}
        <ReactFullpage
          ref={this.myRef}
          // Required when using extensions
          pluginWrapper={pluginWrapper}
          licenseKey={"E8013069-1FEF4426-A19B31C5-33E68BBC"} // Get one from https://alvarotrigo.com/fullPage/pricing/
          navigation
          anchors={[
            "Hero",
            "About",
            "Work",
            "Services",
            "Contact",
          ]}
          css3={true}
          easingcss3={"ease-in"}
          scrollingSpeed={500}
          sectionSelector={SECTION_SEL}
          afterLoad={this.afterLoad.bind(this)}
          onLeave={this.onLeave.bind(this)}
          onSlideLeave={this.onSlideLeave.bind(this)}
          afterSlideLoad={this.afterSlideLoad.bind(this)}
          sectionsColor={this.state.sectionsColor}
          dragAndMoveKey={
            "ZXRhbGVzLWNvcnBvcmF0ZS5uZXRsaWZ5LmFwcF84Q0NaSEpoWjBGdVpFMXZkbVU9WWh2"
          } //2757DF9C-36FE4BC4-B9CE596A-E3CDE8FB <-- Activation key
          dragAndMove={false}
          scrollHorizontallyKey={
            "ZXRhbGVzLWNvcnBvcmF0ZS5uZXRsaWZ5LmFwcF9mN2ZjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDVDRTU="
          } //1CB886A6-6040472A-B02CD643-8BA5B2EC <-- Activation key
          scrollHorizontally={true}
          controlArrows={false}
          menu={true}
          autoScrolling={true}
          normalScrollElements={".scrollable-content"}
          render={(comp) => (
            <ReactFullpage.Wrapper>
              {fullpages.map(({page}) => (
                <div key={page} className={SEL}>
                  <div
                    style={{
                      width: "100vw",
                      height: "calc(100vh - calc(100vh - 100%))",
                      zIndex: -1,
                      position: "absolute",
                      pointerEvents: "none",
                    }}
                    className={
                      this.state.direction === "up"
                        ? this.state.borderFadingIn
                        ? "fadeBorderInTop" + ((this.state.pageIndex % 2 === 0) ? "Black" : "White")
                        : "fadeBorderOutBottom" + ((this.state.pageIndex % 2 === 0) ? "White" : "Black")
                        : this.state.borderFadingIn
                        ? "fadeBorderInBottom" + ((this.state.pageIndex % 2 === 0) ? "Black" : "White")
                        : "fadeBorderOutTop" + ((this.state.pageIndex % 2 === 0) ? "White" : "Black")
                    }
                  ></div>
                  <div
                    style={{
                      width: "100vw",
                      height: "calc(100vh - calc(100vh - 100%))"
                    }}
                    className={
                       this.state.borderFadingIn
                        ? "border-fade-in"
                        : "border-fade-out"
                    }
                  >
                    {page === "firstPage" && (
                      <div className={"full-parent-size"}>
                        <FirstPage
                          index={this.state.pageIndex}
                          animation={this.state.heroAnimation}
                        />
                      </div>
                    )}
                    {page === "secondPage" && (
                      <div
                        className={"full-parent-size"}
                        style={{
                          backgroundColor: "#FFFCF8",
                        }}
                      >
                        <SecondPage
                          borderFadeIn={this.state.borderFadingIn}
                          taleLoaded={this.state.taleLoaded}
                          aboutLoaded={this.state.aboutLoaded}
                          firstAboutLoad={this.state.firstAboutLoad}
                        />
                      </div>
                    )}
                    {page === "thirdPage" && (
                      <div
                        className={"full-parent-size"}
                        style={{
                          backgroundColor: "#0B0B0B",
                        }}
                      >
                        <ThirdPage
                          animation={this.state.projectAnimation}
                          borderFadeIn={this.state.borderFadingIn}
                        />
                      </div>
                    )}
                    {page === "fourthPage" && (
                      <div
                        className={"full-parent-size"}
                        style={{
                          backgroundColor: "#FFFCF8",
                        }}
                      >
                        <SecondPage
                          borderFadeIn={this.state.borderFadingIn}
                          taleLoaded={this.state.taleLoaded}
                          aboutLoaded={this.state.aboutLoaded}
                          firstAboutLoad={this.state.firstAboutLoad}
                        />
                      </div>
                    )}
                    {page === "fifthPage" && (
                      <div
                        className={"full-parent-size"}
                        style={{
                          backgroundColor: "#0B0B0B",
                        }}
                      >
                        <FifthPage
                          animation={this.state.contactAnimation}
                          borderFadeIn={this.state.borderFadingIn}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </ReactFullpage.Wrapper>
          )}
        />
      </div>
    );
  }
}
