import React from "react";
import { MDBRow, MDBCardBody, MDBCardText } from "mdbreact";
import Image from "gatsby-image";
import { Link } from "gatsby";

const Card = ({ clientTitle, clientDescription, clientImages }) => {
  const item = clientImages.map((child) =>
    Object.values(child).map((fluid) => fluid.fluid)
  );
  return (
    <MDBRow
      key={clientTitle}
      style={{ background: "#0B0B0B" }}
      className="card-row"
    >
      <div className="test-card-row">
        <Image
          className="custom-image-card"
          fluid={item[0]}
          alt={clientTitle}
        />
        <MDBCardBody cascade className="custom-card-body">
          <MDBCardText className="custom-card-title">
            <span className={"custom-card-text"}>{clientTitle}</span>
          </MDBCardText>
          <MDBCardText className="custom-card-description">
            {clientDescription}
            <br></br>

            <div className="custom-card-view-link">
              <Link to={`/${clientTitle}`} style={{ fontSize: "1.5rem" }}>
                view
              </Link>
            </div>
          </MDBCardText>
        </MDBCardBody>
      </div>
    </MDBRow>
  );
};

export default Card;
