import React from "react";
import Carousel from "react-multi-carousel";
import { StaticQuery, graphql } from "gatsby";
import Card from "./Card";
import "react-multi-carousel/lib/styles.css";
import { MDBContainer, MDBRow } from "mdbreact";
import InfoBar from "../infobar/InfoBar";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    swipeable: true,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    swipeable: true,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    swipeable: true,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    swipeable: true,
    partialVisibilityGutter: 0,
  },
};

class WithScrollbar extends React.Component {
  state = { additionalTransfrom: 0 };

  render() {
    const data = this.props.data;
    const {
      allMarkdownRemark: { nodes },
    } = data;

    const CustomSlider = ({ carouselState }) => {
      let value = 0;
      let carouselItemWidth = 0;
      if (this.Carousel) {
        carouselItemWidth = this.Carousel.state.itemWidth;
        const maxTranslateX = Math.round(
          // so that we don't over-slide
          carouselItemWidth *
            (this.Carousel.state.totalItems -
              this.Carousel.state.slidesToShow) +
            150
        );
        value = maxTranslateX / 100; // calculate the unit of transform for the slider
      }

      const { transform } = carouselState;
      return (
        <div className="custom-slider">
          <input
            type="range"
            value={Math.round(Math.abs(transform) / value)}
            // defaultValue={0}
            max={
              (carouselItemWidth *
                (carouselState.totalItems - carouselState.slidesToShow) +
                (this.state.additionalTransfrom === 150 ? 0 : 150)) /
              value /
              2.5
            }
            onChange={(e) => {
              if (this.Carousel.isAnimationAllowed) {
                this.Carousel.isAnimationAllowed = false;
              }
              const nextTransform = e.target.value * value;
              const nextSlide = Math.round(nextTransform / carouselItemWidth);
              if (
                e.target.value === 0 &&
                this.state.additionalTransfrom === 150
              ) {
                this.Carousel.isAnimationAllowed = true;
                this.setState({ additionalTransfrom: 0 });
              }
              this.Carousel.setState({
                transform: -nextTransform, // padding 20px and 5 items.
                currentSlide: nextSlide,
              });
            }}
            className="custom-slider__input"
          />
        </div>
      );
    };
    return (
      <MDBContainer
        fluid
        className={
          !this.props.borderFadeIn
            ? "case-container carousel-toggle-visible"
            : "case-container carousel-toggle-invisible"
        }
      >
        <MDBRow className={"case-row-container"}>
          {this.props.animation && (
            <InfoBar
              text="OUR WORK"
              color="white"
              marginLeft="-2rem"
              marginBottom="8vh"
            />
          )}
          <div className="project-title">Featured projects</div>
          <Carousel
            arrows={false}
            draggable={true}
            autoPlay={false}
            ssr={false}
            infinite={true}
            keyBoardControl={true}
            ref={(el) => (this.Carousel = el)}
            partialVisible={false}
            customButtonGroup={<CustomSlider />}
            itemClass="image-item"
            responsive={responsive}
            containerClass="carousel-container-with-scrollbar"
          >
            {Object.values(nodes).map((mark, key) => {
              if (mark) {
                if (mark.frontmatter.clientTitle !== null) {
                  return (
                    <Card
                      key={key}
                      clientTitle={mark.frontmatter.clientTitle}
                      clientDescription={mark.frontmatter.clientDescription}
                      clientImages={mark.frontmatter.clientImages}
                    />
                  );
                }
                return null;
              }
              return null;
            })}
          </Carousel>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default ({ animation, borderFadeIn }) => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark {
          nodes {
            frontmatter {
              clientTitle
              clientDescription
              clientImages {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <WithScrollbar
        data={data}
        animation={animation}
        borderFadeIn={borderFadeIn}
      />
    )}
  />
);
